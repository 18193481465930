
import KirbyLink from "./kirbylink.vue";
export default {
  name: "bethechange",
  props: {
    content: Object,
  },
  components: {
    KirbyLink,
  },
};
